<template>
  <div id="app" style="margin-top: 0px; padding-top: 0px;">
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5GTWXFK" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>