import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: () => import("@/components/landing/Landing") },
    { path: '/register', component: () => import("@/components/register/Register") },
    { path: '/thankyou', component: () => import("@/components/thankyou/Thankyou") }
  ]
});

Vue.config.productionTip = false

new Vue({
  router,
  el: "#app",
  render: h => h(App),
}).$mount('#app')
